import {
  Directive,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
  inject,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { Action, Resource, RoleService } from '../../auth/store/role.service';

export type Otherwise = 'DISABLE' | 'HIDE' | 'POPUP';

@Directive({
  standalone: true,
  selector: '[hasPermission]',
  providers: [],
})
export class HasPermissionDirective {
  resource?: Resource;
  actions: Action[] = [];
  otherwise?: Otherwise;
  roleService = inject(RoleService);
  messageService = inject(MessageService);
  el?: any;

  constructor(
    private renderer: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.el = this.viewContainer.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
  }

  @Input()
  set hasPermission(permission: {
    onResource: Resource;
    onActions: Action[];
    otherwise: Otherwise;
  }) {
    // console.log(permission);

    this.resource = permission?.onResource;
    this.actions = permission?.onActions;
    this.otherwise = permission?.otherwise;
    this.updateView();
  }

  private updateView() {
    // console.log('this resource', this.resource);

    // console.log(this.roleService.hasPermission(this.resource, this.actions));

    if (this.roleService.hasPermission(this.resource, this.actions)) {
      return true;
    }
    if (this.otherwise === 'HIDE') {
      this.viewContainer.clear();
      return false;
    }

    if (this.otherwise === 'DISABLE') {
      this.el.disabled = 'true';
      this.renderer.listen(this.el, 'click', ($event: Event) => {
        console.log($event);
        $event.stopPropagation();
        $event.preventDefault();
        $event.stopImmediatePropagation();
        return false;
      });
      return false;
    }

    if (this.otherwise === 'POPUP') {
      if (this.el.tagName === 'P-INPUTSWITCH') {
        this.renderer.listen(
          this.el.querySelector('.p-inputswitch-slider'),
          'click',
          ($event: Event) => {
            $event.stopPropagation();
            $event.preventDefault();
            $event.stopImmediatePropagation();
            this.messageService.add({
              key: 'root-msgs',
              severity: 'error',
              summary: 'Forbidden',
              detail: 'You are not authorized to perform this operation',
            });

            return false;
          }
        );
      }
      console.log('tag name:: ', this.el.getAttribute('pbutton'));
      this.renderer.listen(this.el, 'click', ($event: Event) => {
        console.log($event.target);

        $event.stopPropagation();
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.messageService.add({
          key: 'root-msgs',
          severity: 'error',
          summary: 'Forbidden',
          detail: 'You are not authorized to perform this operation',
        });

        return false;
      });
      return false;
    }
    return true;
  }
}
